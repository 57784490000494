// import request from "../../../../adminplat/src/utils/request";

export default ({ request }) => ({
   // 提报记录列表
  getRecordList(params) {
    return request({
      url: '/newApp/record/list',
      method: 'get',
      params,
    });
  },
  // 待办列表
  getTaskList(params) {
    return request({
      url: '/newApp/task/list',
      method: 'get',
      params,
    });
  },
  // 获取异常提报设备
  abnormaDeviceList() {
    return request({
      url: '/newApp/abnorma/device',
      method: 'get'
    });
  },
  // 故障类型设备
  repairDeviceList() {
      return request({
        url: '/newApp/repair/device',
        method: 'get'
      });
    },
  // 提报原因列表 更换项目列表，
  reasonList(params) {
    return request({
      url: '/newApp/reason/list',
      method: 'get',
      params,
    });
  },
  // 提交异常提报
  SubmissionCreate (data) {
    return request({
      url: '/newApp/abnormal/create',
      method: 'post',
      data
    })
  },
  // 添加定期更换
  submitReplacement (data) {
    return request({
      url: '/newApp/regular/regular',
      method: 'post',
      data
    })
  },
  // 人员选择
  teamList (data) {
    return request({
      url: '/psl/team/user/get/drop',
      method: "post",
      data
    })
  },
  // 提交故障报修
  submitBaoxiu (data) {
    return request({
      url: '/dhk/repair/create/app',
      method: 'post',
      data,
      timeout: 1000 * 60 * 5
    })
  },
  // 定期更换明细
  getExchangeDetail (id) {
    return request({
      url: `/newApp/regular/info/${id}`
    });
  },
  // 异常待机详情
  getExcetionDetail (id) {
    return request({
      url: `/newApp/abnormal/detail/${id}`
    });
  },
  // 审批意见
   abnormalApprove (data) {
    return request({
      url: '/newApp/abnormal/approve',
      method: "post",
      data
    })
  },
  // 设备维修指派信息
 searchList (data) {
    return request({
      url: '/dhk/repair/search',
      method: "post",
      data
    })
  },
  // 维修单的维修信息/dhk/repair/detail/list
 detailList(data) {
    return request({
      url: '/dhk/repair/detail/list',
      method: "post",
      data
    })
  },
  // 维修单开始工作/dhk/repair/work/start

 startWork(data) {
    return request({
      url: '/dhk/repair/work/start',
      method: "post",
      data
    })
  },
  // 维修单确认完成/dhk/repair/work/finish
   finishWork(data) {
    return request({
      url: '/dhk/repair/work/finish',
      method: "post",
      data
    })
  },
  // 维修报告提交
  submitReport (data) {
    return request({
      url: '/dhk/repair/detail/create/app',
      method: 'post',
      data,
      timeout: 1000 * 60 * 5
    })
  },
  // 历史维修记录
  getHistoryMantainList(data) {
    return request({
      url: `/dhk/repair/list/hist`,
      method: 'post',
      data
    });
  },
  // 获取点检记录设备
   deviceList (data) {
    return request({
      url: '/newApp/maintain/device',
      method: "get",
      params: data
    })
  },
  // 保养记录
   listmaintainSubmit (data) {
    return request({
      url: '/dkh/maintain/list',
      method: "post",
      data
    })
  },
  getMaintainDetail (deviceMaintainId) {
    return request({
      url: `dkh/maintain/detail`,
      method: 'post',
      data: { deviceMaintainId }
    })
  },
  getMaintainDetaiList (deviceMaintainId) {
    return request({
      url: `dkh/maintain/detail/list`,
      method: 'post',
      data: { deviceMaintainId }
    })
  },
  startMaintain (id) {
    return request({
      url: `newApp/check/start`,
      method: 'post',
      data: { id }
    })
  },
  // 提交保养
  submitMaintain (data) {
    return request({
      data,
      url: `/dkh/maintain/modify`,
      method: 'post',
    })
  },
  //保养切换
   getPointListMaintain (id) {
    return request({
      url: `/dkh/maintain/switch/maintain/${id}`,
      method: "post",
    });
  },
  // 点位详情/newApp/check/pointList
  pointList (data) {
    return request({
      url: '/newApp/check/pointList',
      method: "get",
      params: data
    })
  },
  // 申请取消保养
  checkCancel (data) {
    return request({
      url: '/newApp/check/cancel',
      method: 'post',
      data
    })
  },
  // 获取点检记录
   checkList (data) {
    return request({
      url: '/newApp/check/list',
      method: "get",
      params: data
    })
  },
  // 点检记录详情/newApp/check/detail/{{appCheckDocumentId}}
   checkDetailList (appCheckDocumentId) {
    return request({
      url: `/newApp/check/detail/${appCheckDocumentId}`,
      method: "get",
    })
  }
});
