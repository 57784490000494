export default ({request}) => ({
  // 获取项目列表
  ProjectList(params) {
    return request({
      url: '/prepressProject/list',
      method: 'get',
      params,
    });
  },
  // 删除项目
  deleteProject(id) {
    return request({
      url: `/prepressProject/deleteProject/${id}`,
      method: 'delete',
    });
  },
// 获取类型列表
  ProjectTypeList() {
    return request({
      url: '/prepressProject/typeList',
      method: 'get'
    });
  },
  // 添加项目
  ProjectAdd(data) {
    return request({
      url: '/prepressProject/add',
      method: 'post',
      data,
    });
  },
  // 原稿文件列表
  fileList(params) {
    return request({
      url: '/prepressProject/fileList',
      method: 'get',
      params,
    });
  },
  // 删除原稿文件
  deleteFile(id) {
    return request({
      url: `/prepressProject/deleteFile/${id}`,
      method: 'delete',
    });
  },
  // 处理文件列表
  resultList(params) {
    return request({
      url: '/prepressProject/resultList',
      method: 'get',
      params,
    });
  },
  // 删除处理文件
  deleteResult(id) {
    return request({
      url: `/prepressProject/deleteResult/${id}`,
      method: 'delete',
    });
  },
  // 获取类型参数
  typeDetail(id) {
    return request({
      url: `/prepressProject/typeDetail/${id}`,
      method: 'get',
    });
  },
  // 客户保存类型参数
  saveUserTypeDetail(data) {
    return request({
      url: '/prepressProject/saveUserTypeDetail',
      method: 'post',
      data,
    });
  },
  // 获取历史记录
  recordList(params) {
    return request({
      url: '/prepressProject/recordList',
      method: 'get',
      params,
    });
  },
  projectSubmit(data) {
    return request({
      url: '/prepressProject/submit',
      method: 'post',
      data,
    });
  },
});
