<template>
  <div id="Header">
    <header>
      <div class="header-box">
        <ul class="head">
          <li id="logo">
            <img class="img" src="~@/assets/img/Slice 19.png" @click="ToHomepage" alt="" />
          </li>
          <li v-if="userInfo.teamId" class="nav" @click="MenuAct">
            <router-link :to="`/PrintSteward`" class="FristPage" :active-class="'active'">
              印刷管家
            </router-link>
          </li>
<!--          <li class="nav">-->
<!--            <router-link :to="'/BeeEnjoy'" class="FristPage" :active-class="'active'">-->
<!--              蜂享优印-->
<!--            </router-link>-->
<!--          </li>-->
<!--          <li class="nav">-->
<!--            <router-link :to="'/DigitalFactory'" class="FristPage FristPageFactory" :active-class="'active'">-->
<!--              数字化工厂-->
<!--            </router-link>-->
<!--          </li>-->
<!--          <li class="nav" @click="CourseMenuAct">-->
<!--            <router-link :to="'/Course'" class="FristPage" :active-class="'active'">-->
<!--              学习园地-->
<!--            </router-link>-->
<!--          </li>-->
        </ul>

        <div class="right-box">
          <div class="name">
            <span style="color:#F3A000">{{ userInfo.userName }}</span>
          </div>
          <!--          <el-tooltip class="item" effect="dark" :content="userInfo.userName" placement="bottom">-->
          <!--            <div class="name">-->
          <!--&lt;!&ndash;              您好！用户&ndash;&gt;-->
          <!--&lt;!&ndash;              <span style="color:#F3A000">{{ userInfo.userName }}</span>&ndash;&gt;-->
          <!--            </div>-->
          <!-- </el-tooltip> -->
          <el-dropdown>
            <div class="block">
              <el-avatar
                :size="40"
                :src="defaultUrl ? defaultUrl : require(`@/assets/img/headerImg.png`)"
              ></el-avatar>
              <!--              <el-avatar :size="54" :src="require(`@/assets/img/headerImg.png`)"></el-avatar>-->
            </div>
            <el-dropdown-menu slot="dropdown" style="width: 148px;padding: 10px 0px 0px">
              <ol>
                <li class="li-text li1" @click="handleChack">
                  <div style="display: flex;padding: 10px 24px">
                    <div style="padding-right: 11px;">
<!--<svg-icon icon-class="查看资料" style="font-size: 16px"/>-->
                      <img src="@/assets/img/Slice 34.png" alt="img" />
                    </div>
                    查看资料
                  </div>
                </li>
                <li class="li-text li1" @click="handleEdit">
                  <div style="display: flex;padding: 10px 24px;margin-bottom: 10px">
                    <div style="padding-right: 11px;">
                      <img src="@/assets/img/Slice 33.png" alt="img" />
                    </div>
                    编辑资料
                  </div>
                </li>
                <div style="border-top: 1px solid #EBEBEB;margin: 10px 24px"/>
                <li class="li-text li1" @click="handleEditPhone">
                  <div style="display: flex;padding: 10px 24px">
                    <div style="padding-right: 6px;">
                      <img src="@/assets/img/Slice 40.png" alt="img" />
                    </div>
                    编辑手机号
                  </div>
                </li>
                <li class="li-text li1" @click="handleEditPassword">
                  <div style="display: flex;padding: 10px 24px;margin-bottom: 10px">
                    <div style="padding-right: 11px;">
                      <img src="@/assets/img/Slice 35.png" alt="img" />
                    </div>
                    编辑密码
                  </div>
                </li>
              </ol>
              <div style="border-top: 1px solid #EBEBEB;margin: 10px 24px"></div>
              <ol>
                <li class="li-text li1" @click="handleSpace" v-if="prepressShow">
                  <div style="display: flex;padding: 10px 24px; ">
                    <div style="padding-right: 11px;">
                      <img src="@/assets/img/slice.png" alt="img" />
                    </div>
                    空间使用
                  </div>
                </li>
                <li class="li-text li1" @click="hamdleInfo">
                  <div style="display: flex;padding: 10px 24px">
                    <div style="padding-right: 11px;">
                      <img src="@/assets/img/Slice 36.png" alt="img" />
                    </div>
                    消息中心
                  </div>
                </li>
                <li class="li-text li1" @click="exit">
                  <div style="display: flex;padding: 10px 24px; margin-bottom: 10px">
                    <div style="padding-right: 11px;">
                      <img src="@/assets/img/Slice 37.png" alt="img" />
                    </div>
                    退出登录
                  </div>
                </li>
              </ol>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <!--  查看用户资料弹窗  -->
      <el-dialog
        :title="dialog.title[dialog.status]"
        :visible.sync="dialog.chack"
        :modal-append-to-body="false"
        :append-to-body="false"
        :close-on-click-modal="false"
        width="500px"
        style="margin-top: 15vh"
      >
        <div style="display: flex; align-items: center; margin-bottom: 17px;">
          <div style="padding-right: 30px;padding-left: 5px">
            <el-avatar
              :size="70"
              :src="defaultUrl ? defaultUrl : require(`@/assets/img/headerImg.png`)"
            ></el-avatar>
            <!--            <el-avatar v-else :src="userInfo.url" :size="100" />-->
          </div>
          <div>
            <div class="padding">
              账号呢称：
              <span>{{ userInfo.userName }}</span>
            </div>
            <div class="padding">
              联系电话：
              <span>{{ userInfo.phone }}</span>
            </div>
            <div class="padding">
              联系邮箱：
              <span>{{ userInfo.email }}</span>
            </div>
            <div class="padding">
              企业名称：
              <span>{{ userInfo.teamName }}</span>
            </div>
          </div>
        </div>
      </el-dialog>
      <!--   编辑用户资料   -->
      <el-dialog
        :title="dialog.title[dialog.status]"
        :visible.sync="dialog.edit"
        :modal-append-to-body="false"
        :append-to-body="false"
        :close-on-click-modal="false"
        width="600px"
        style="margin-top: 15vh"
      >
        <el-form
          label-position="left"
          ref="change"
          :model="change"
          :rules="rules"
          label-width="100px"
          class="form1"
        >
          <div style="display: flex; align-items: center">
            <div style="padding-right: 30px">
              <el-form-item label-width="0">
                <div class="headImg">
                  <el-avatar
                    v-if="userInfo.url === ''"
                    :size="70"
                    :src="require(`@/assets/img/headerImg.png`)"
                  ></el-avatar>
                  <el-avatar v-else class="avatar" :src="userInfo.url" :size="70" />
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :http-request="uploadFile"
                    action="#"
                    :on-change="handleChange"
                    :show-file-list="false"
                    :auto-upload="false"
                    :file-list="fileList"
                    accept=".jpg,.jpeg"
                  >
                    <!--        :auto-upload="false"            -->
                    <el-button class="buttonUp" type="text" size="small" :loading="loading">
                      上传头像
                    </el-button>
                  </el-upload>
                </div>
              </el-form-item>
            </div>

            <div style="width: 450px;">
              <el-form-item label="昵称:" prop="name">
                <el-input
                  v-model.trim="change.name"
                  maxlength="30"
                  placeholder="请输入账号昵称"
                  show-word-limit
                  clearable
                />
              </el-form-item>

              <!--              <el-form-item label="联系电话:" prop="phone">
              <el-input
                  v-model.trim="change.phone"
                  maxlength="11"
                  placeholder="请输入联系电话"
                 show-word-limit
                 clearable
               />
             </el-form-item>

             <el-form-item label="验证码:" prop="code">
              <el-input v-model.trim="change.code" maxlength="6" placeholder="请输入验证码" />
               <div class="captcha-btn">
                  <el-button v-show="AuthCode2" type="text" @click="getNewCode">
                   获取验证码
                 </el-button>
                 <el-button v-show="!AuthCode2" type="text">
                   {{ auth_time2 }}秒后重新发送
                  </el-button>
                </div>
             </el-form-item>-->

              <el-form-item label="联系邮箱:" prop="">
                <el-input
                  v-model.trim="change.email"
                  maxlength="30"
                  placeholder="请输入邮箱"
                  show-word-limit
                  clearable
                />
              </el-form-item>
            </div>
          </div>
        </el-form>
        <el-button :loading="loading" class="button" @click="handleEditInfoConfirm">确定</el-button>
      </el-dialog>
<!--   编辑旧手机号   -->
      <el-dialog
          title="编辑手机号"
          :visible.sync="dialog.phone"
          :modal-append-to-body="false"
          :append-to-body="false"
          :close-on-click-modal="false"
          width="500px"
          style="margin-top: 15vh"
      >
        <div class="form-box" style="padding: 10px 5px 0px;">
          <el-form ref="change" label-position="left" label-width="80px">
            <el-form-item label="原手机号:">
              <el-input v-model="phone" :disabled="true" @input="changeValue" />
            </el-form-item>

            <el-form-item label="验 证 码:">
              <el-input v-model.trim="oldCode.code" placeholder="请输入" maxlength="6" />
              <div class="captcha-btn">
                <el-button v-show="AuthCode" type="text" @click="changePhone">获取验证码</el-button>
                <el-button v-show="!AuthCode" type="text">{{ auth_time }}秒后重新发送</el-button>
              </div>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button class="buttonCancel" @click="dialog.phone = false">取 消</el-button>
            <el-button :loading="loading" class="button" @click="nextCheack">下一步</el-button>
          </div>
        </div>

      </el-dialog>
      <!-- 弹出框：新手机号 -->
      <el-dialog
          title="编辑手机号"
          :modal-append-to-body="false"
          :append-to-body="false"
          :close-on-click-modal="false"
          width="500px"
          :visible.sync="dialogNewPhone"
          style="margin-top: 15vh"
      >
        <div class="form-box" style="padding: 10px 5px 0px;">
          <el-form label-position="left" label-width="80px">
            <el-form-item label="新手机号:">
              <el-input v-model.trim="NewParam.phone" clearable maxlength="11" show-word-limit />
            </el-form-item>

            <el-form-item label="验 证 码:">
              <el-input v-model.trim="NewParam.code" maxlength="6" />
              <div class="captcha-btn">
                <el-button v-show="AuthCode2" type="text" @click="getNewCode">获取验证码</el-button>
                <el-button v-show="!AuthCode2" type="text">{{ auth_time2 }}秒后重新发送</el-button>
              </div>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button class="buttonCancel" @click="dialogNewPhone = false">取 消</el-button>
            <el-button type="primary" :loading="loading" class="button" @click="finishChange">确定</el-button>
          </div>
        </div>
      </el-dialog>
      <!--   编辑密码    -->
      <el-dialog
        :title="dialog.title[dialog.status]"
        :visible.sync="dialog.password"
        :modal-append-to-body="false"
        :append-to-body="false"
        :close-on-click-modal="false"
        width="500px"
        style="margin-top: 15vh"
      >
        <el-form
          ref="param"
          :model="param"
          label-position="left"
          label-width="100px"
          :rules="rules"
          class="form1"
        >
          <el-form-item label="原密码：" prop="password">
            <el-input
                :key="passwordType"
              v-model.trim="param.password"
                :type="passwordType"
              name="password"
              placeholder="请输入"
              :max="16"
            />
            <span class="show-pwd" @click="showPwd">
            <svg-icon style="vertical-align: 0.2em;" :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
          </span>
          </el-form-item>

          <el-form-item label="新密码：" prop="newPassword1">
            <el-input
                :key="passwordType2"
                :type="passwordType2"
              v-model.trim="param.newPassword1"
              placeholder="请输入"
              :max="16"
            />
            <span class="show-pwd" @click="showPwd2">
            <svg-icon style="vertical-align: 0.2em;" :icon-class="passwordType2 === 'password' ? 'eye' : 'eye-open'" />
          </span>
          </el-form-item>

          <el-form-item label="确认密码：" prop="newPassword2">
            <el-input
                :key="passwordType3"
                :type="passwordType3"
              v-model.trim="param.newPassword2"
              placeholder="请输入"
              :max="16"
            />
            <span class="show-pwd" @click="showPwd3">
            <svg-icon style="vertical-align: 0.2em;" :icon-class="passwordType3 === 'password' ? 'eye' : 'eye-open'" />
          </span>
          </el-form-item>
          <el-button :loading="loading" class="button" @click="handleEditPasswordConfirm">
            确定
          </el-button>
        </el-form>
      </el-dialog>
      <!--   系统消息   -->
      <el-dialog
        :title="dialog.title[dialog.status]"
        :visible.sync="dialog.info"
        :modal-append-to-body="false"
        :append-to-body="false"
        :close-on-click-modal="false"
        width="800px"
      >
        <div
          style="text-align: center; color: #231815; height: 377px;display: flex; justify-content: center; flex-direction: column; align-items: center;"
          v-if="this.infoData.length === 0"
        >
          <img
            style="display: block;width:auto;margin: 0 auto;"
            src="@/assets/img/Slice 38.png"
            alt="img"
          />
          <div style="font-size:16px;color: #999;margin: 20px 0;">暂无数据</div>
        </div>
        <ul v-else class="info-box" style="height: 377px;">
          <!--列表项-->
          <li
            v-for="(item, index) in infoData"
            :key="index"
            class="content"
            :class="item.hasRead === 1 ? 'active' : ''"
          >
            <!--消息内容-->
            <el-tooltip :content="item.message">
              <div class="text">{{ item.message }}</div>
            </el-tooltip>

            <!--日期-->
            <div class="date">{{ item.date }}</div>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer" style="padding-bottom: 25px"></span>
      </el-dialog>
      <!--   空间使用    -->
      <el-dialog
          title="空间使用"
          :visible.sync="dialog.space"
          :modal-append-to-body="false"
          :append-to-body="false"
          :close-on-click-modal="false"
          width="500px"
          style="margin-top: 15vh"
      >
        <div>
          <chartsPanel2 :id="'panel'" :value="prepress.rate" :allSpace="prepress.allSpace" :usedSpace="prepress.usedSpace"></chartsPanel2>
        </div>
      </el-dialog>
    </header>
  </div>
</template>

<script>
import API from '@/api';
import cookies from '@/utils/cookies';
import { store } from '@/store/index.js';
import { isEmail } from '@/utils/validate';
import chartsPanel2 from '@/components/Charts/chartsPanel2';
// import {request} from "@/api/request";
// import * as promise from "autoprefixer";
export default {
  name: 'HeaderName',
  components: {chartsPanel2},
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (value !== this.param.newPassword1) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      // 空间使用
      prepress: {
        allSpace: null,
        rate: null,
        usedSpace: null
    },
      prepressShow: false,
      defaultUrl: '',
      data: '', // 个人资料
      fileList: [], // 上传的文件列表
      listdata: new FormData(),
      phone: '',
      NewParam: {
        phone: '',
        code: '', // 新手机号验证码
      },
      AuthCode: true, // 控制倒计时显示
      auth_time: 60, // 验证码倒计时
      AuthCode2: true, // 新手机号控制显示
      auth_time2: 60, // 新手机号倒计时
      oldCode: {
        code: '', // 原手机号验证码
      },
      loading: false, // 加载
      dialogNewPhone: false,
      dialog: {
        chack: false,
        edit: false,
        phone: false,
        password: false,
        info: false,
        space:false,
        title: {
          chack: '查看用户资料',
          edit: '编辑用户资料',
          phone: '编辑手机号',
          password: '编辑密码',
          info: '系统消息',
        },
        status: '',
      },
      // 密码
      param: {
        password: '',
        newPassword1: '',
        newPassword2: '',
      },
      change: {
        name: '',
        email: '',
        phone: '',
        code: '', // 新手机号验证码
      },
      infoData: [],
      info: {
        count: 10,
        page: 0,
        total: 0,
        type: '',
      },
      userInfo: {
        userName: '',
        url: '',
        phone: '',
        email: '',
        teamName: '',
      },
      passwordType: 'password',
      passwordType2: 'password',
      passwordType3: 'password',
      VUE_APP_USERCONSOLE: process.env.VUE_APP_USERCONSOLE,
      VUE_APP_HOME: process.env.VUE_APP_HOME,
      VUE_APP_LOGIN: process.env.VUE_APP_LOGIN,
      router: '',
      rules: {
        name: [{ required: true, trigger: 'change', message: '请输入账号昵称' }],
        code: [
          { required: true, trigger: 'change', message: '请输入验证码' },
          {
            pattern: /^\d{6}$/,
            message: '请输入验证码的正确格式',
            trigger: 'blur',
          },
        ],
        email: [
          { required: true, trigger: 'change', message: '请输入邮箱' },
          {
            pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            message: '请输入邮箱的正确格式',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '请输入旧密码', trigger: 'blur' },
          {
            pattern: /^[\w!@#$%^&*]{6,16}$/,
            message: '请输入6-16个非空格字符',
            trigger: 'blur',
          },
        ],
        newPassword1: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            pattern: /^[\w!@#$%^&*]{6,16}$/,
            message: '请输入6-16个非空格字符',
            trigger: 'blur',
          },
        ],
        newPassword2: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: validatePass2, trigger: 'change' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3-9][0-9]{9}$/, message: '请输入正确手机号', trigger: 'blur' },
        ],
      },
    };
  },

  computed: {
    // 上传的地址
    Url() {
      return `${process.env.VUE_APP_API}/psl/user/url/modify`;
    },
  },
  created() {
    this.getUserInfo();
    this.getmessage();
    this.getPrepress()
  },
  methods: {
    changeValue(value) {
      this.phone = value.replace(/[^\d]/g, '');
    },
    MenuAct() {
      this.$store.dispatch('reSetMenu', '/PrintSteward/PrintHome');
      this.$store.dispatch('reSetMenuArr', ['/PrintSteward/PrintHome']);
    },
    CourseMenuAct() {
      this.$store.dispatch('reSetCourseMenu', '/Course/Selection');
    },
    // 上传图片前
    uploadFile(file) {
      this.listdata = new FormData();
      this.listdata.append('file', file.file);
    },
    // 上传头像
    handleChange(file) {
      const isType = file.name.split('.');
      const pdf = isType[isType.length - 1] === 'jpg';
      const jpeg = isType[isType.length - 1] === 'jpeg';
      const isLt1G = file.size / 1024 / 1024 < 5;
      if (!pdf && !jpeg) {
        this.$message.error('上传失败！上传图片只能是JPG和JPEG格式！');
        this.fileList = [];
        return;
      }
      if (!isLt1G) {
        this.$message.error('上传失败！上传文件大小超过5MB！');
        this.fileList = [];
        return;
      }
      const tempUrl = window.URL.createObjectURL(file.raw);
      this.userInfo.url = tempUrl;
      this.listdata = new FormData();
    },

    // 获取消息列表
    getmessage() {
      API.message(this.info)
        .then((res) => {
          this.info.total = res.message.data.totalCount;
          this.infoData = res.message.data.list;
        })
        .catch((mgs) => {
          console.log(mgs);
        });
    },
    handleCurrentChange(page) {
      this.info.page = page - 1;
      this.getmessage();
    },
    // 返回portal首页
    ToHomepage() {
      window.open(`${this.VUE_APP_HOME}`);
    },
    // 查看用户资料
    handleChack() {
      this.dialog.status = 'chack';
      this.dialog.chack = true;
    },
    // 密码
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      // this.$nextTick(() => {
      //   this.$refs.password.focus()
      // })
    },
    showPwd2() {
      if (this.passwordType2 === 'password') {
        this.passwordType2 = '';
      } else {
        this.passwordType2 = 'password';
      }
      // this.$nextTick(() => {
      //   this.$refs.password.focus()
      // })
    },
    showPwd3() {
      if (this.passwordType3 === 'password') {
        this.passwordType3 = '';
      } else {
        this.passwordType3 = 'password';
      }
      // this.$nextTick(() => {
      //   this.$refs.password.focus()
      // })
    },
    // 编辑手机号
    handleEditPhone() {
      this.oldCode.code = '';
      this.phone = this.userInfo.phone;
      this.dialog.phone = true;
      this.$nextTick(() => {
        // 清除验证
        this.$refs.change.clearValidate();
      });
    },
    // 发送原手机号的验证码
    changePhone() {
      // if (cookies.get('token')) {
      API.phone()
        .then((res) => {
          if (res.message.success === true) {
            this.$message({
              message: res.message.message,
              type: 'success',
              duration: 1000,
            });
            this.AuthCode = false;
            this.auth_time = 60;
            const authTimeTimer = setInterval(() => {
              this.auth_time -= 1;
              if (this.auth_time <= 0) {
                this.AuthCode = true;
                clearInterval(authTimeTimer);
              }
            }, 1000);
          } else {
            this.$message({
              message: res.message.message,
              type: 'warning',
              duration: 1000,
            });
          }
        })
        .catch((mgs) => {
          console.log(mgs);
        });
      // } else {
      //   console.log('登入页面')
      // }
    },
    // 判断验证码格式正则
    cheackCode() {
      const reg = /^\d{6}$/;
      const res = reg.test(this.oldCode.code);
      return res;
    },
    // 判断验证码格式及下一步
    nextCheack() {
      if (this.oldCode.code.length <= 0) {
        this.$message({
          message: '验证码不能为空',
          type: 'warning',
          duration: 1000,
        });
      } else if (!this.cheackCode()) {
        this.$message({
          message: '验证码格式错误',
          type: 'warning',
          duration: 1000,
        });
      } else {
        this.nextPhone();
      }
    },
    // 验证旧手机验证码
    nextPhone() {
      this.loading = true;
      API.cheackPhone(this.oldCode)
        .then((res) => {
          if (res.message.success === true) {
            this.$message({
              message: res.message.message,
              type: 'success',
              duration: 1000,
            });
            this.NewParam.phone = '';
            this.NewParam.code = '';
            this.loading = false;
            this.dialog.phone = false;
            this.dialogNewPhone = true;
          } else {
            this.$message({
              message: res.message.message,
              type: 'warning',
              duration: 1000,
            });
          }
        })
        .catch((mgs) => {
          console.log(mgs);
        })
        .finally((mgs) => {
          this.loading = false;
          console.log(mgs);
        });
    },
    // 新手机号格式
    checkNewPhone() {
      const reg = /^1[3|4|5|7|8][0-9]{9}$/;
      const res = reg.test(this.NewParam.phone);
      return res;
    },
    // 判断新验证码格式
    cheackNewCode() {
      const reg = /^\d{6}$/;
      const res = reg.test(this.NewParam.code);
      return res;
    },
    // 获取新手机号验证码
    getNewCode() {
      if (this.NewParam.phone.length <= 0) {
        this.$message({
          message: '手机号不能为空',
          type: 'warning',
          duration: 1000,
        });
      } else if (!this.checkNewPhone()) {
        this.$message({
          message: '请输入正确的手机号',
          type: 'warning',
          duration: 1000,
        });
      } else {
        API.getNewPhone({ phone: this.NewParam.phone })
          .then((res) => {
            if (res.message.success === true) {
              this.$message({
                message: res.message.message,
                type: 'success',
                duration: 1000,
              });
              this.AuthCode2 = false;
              this.auth_time2 = 60;
              const authTimeTimer2 = setInterval(() => {
                this.auth_time2 -= 1;
                if (this.auth_time2 <= 0) {
                  this.AuthCode2 = true;
                  clearInterval(authTimeTimer2);
                }
              }, 1000);
            } else {
              this.$message({
                message: res.message.message,
                type: 'warning',
                duration: 1000,
              });
            }
          })
          .catch((mgs) => {
            console.log(mgs);
          });
      }
    },
    // 新手机号确定
    finishChange() {
      if (this.NewParam.phone.length <= 0 && this.NewParam.code.length <= 0) {
        this.$message({
          message: '手机或验证码不能为空',
          type: 'warning',
          duration: 1000,
        });
      } else if (!this.checkNewPhone()) {
        this.$message({
          message: '手机号格式错误',
          type: 'warning',
          duration: 1000,
        });
      } else if (!this.cheackNewCode()) {
        this.$message({
          message: '验证码格式错误',
          type: 'warning',
          duration: 1000,
        });
      } else {
        this.loading = true;
        API.cheackNewPhone3(this.NewParam)
          .then((res) => {
            if (res.message.success === true) {
              this.$message({
                message: res.message.message,
                type: 'success',
                duration: 1000,
              });
              this.$confirm('手机号已更换，请重新登录！', '提示', {
                closeOnClickModal: false,
                showCancelButton: false,
                confirmButtonText: '确定',
                type: 'warning',
              })
                .then(() => {
                  store.dispatch('logout').then(() => {
                    cookies.remove('token');
                    window.open(`${this.VUE_APP_HOME}`, '_self');
                  });
                })
                .catch(() => {
                  store.dispatch('logout').then(() => {
                    cookies.remove('token');
                    window.open(`${this.VUE_APP_HOME}`, '_self');
                  });
                });
              this.getInformation();
              this.dialogNewPhone = false;
              this.loading = false;
            } else {
              this.$message({
                message: res.message.message,
                type: 'warning',
                duration: 1000,
              });
            }
          })
          .catch((mgs) => {
            this.loading = false;
            console.log(mgs);
          })
          .finally((mgs) => {
            this.loading = false;
            console.log(mgs);
          });
      }
    },
    // 编辑用户资料
    handleEdit() {
      this.dialog.status = 'edit';
      this.dialog.edit = true;
      this.change.name = this.userInfo.userName;
      // this.change.phone = this.userInfo.phone
      this.userInfo.url = this.defaultUrl;
      this.change.email = this.userInfo.email;
      this.$nextTick(() => {
        // 清除验证
        this.$refs.change.clearValidate();
      });
    },
    // 编辑用户资料确定
    handleEditInfoConfirm() {
      if (this.change.email !== '') {
        if (!isEmail(this.change.email)) {
          this.$message.error('邮箱不合法');
          return false;
        }
      }
      this.listdata = new FormData();
      this.$refs.upload.submit();
      this.$refs.change.validate((valid) => {
        if (valid) {
          this.listdata.append('email', this.change.email);
          this.listdata.append('name', this.change.name);
          // this.listdata.append('file', this.userInfo.url)
          this.loading = true;
          API.name(this.listdata)
            .then((res) => {
              if (res.message.success === true) {
                this.$message({
                  message: res.message.message,
                  type: 'success',
                  duration: 1000,
                });
                this.dialog.edit = false;
                this.loading = false;
                this.fileList = [];
                this.getUserInfo();
              } else {
                this.$message({
                  message: res.message.message,
                  type: 'warning',
                  duration: 1000,
                });
              }
            })
            .catch((mgs) => {
              // this.loading = false
              console.log(mgs);
            })
            .finally(() => {
              // this.loading = false
            });
        }
      });
      return undefined;
    },

    // 编辑密码
    handleEditPassword() {
      this.param = {
        password: '',
        newPassword1: '',
        newPassword2: '',
      };
      this.dialog.status = 'password';
      this.dialog.password = true;
      this.$nextTick(() => {
        // 清除验证
        this.$refs.param.clearValidate();
      });
    },
    // 编辑密码确定
    handleEditPasswordConfirm() {
      this.$refs.param.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (cookies.get('token')) {
            API.repassword(this.param)
              .then((res) => {
                if (res.message.success === true) {
                  this.$message({
                    message: '编辑密码成功',
                    type: 'success',
                    duration: 1000,
                  });
                  this.$confirm('密码已更换，请重新登录！', '提示', {
                    closeOnClickModal: false,
                    showCancelButton: false,
                    confirmButtonText: '确定',
                    type: 'warning',
                  })
                    .then(() => {
                      store.dispatch('logout').then(() => {
                        cookies.remove('token');
                        window.open(`${this.VUE_APP_HOME}`, '_self');
                      });
                    })
                    .catch(() => {
                      store.dispatch('logout').then(() => {
                        cookies.remove('token');
                        window.open(`${this.VUE_APP_HOME}`, '_self');
                      });
                    });
                  this.dialog.password = false;
                  this.loading = false;
                  this.param = {
                    password: '',
                    newPassword1: '',
                    newPassword2: '',
                  };
                } else {
                  this.loading = false;
                  this.$message({
                    message: res.message.message,
                    type: 'error',
                    duration: 1000,
                  });
                }
              })
              .catch((mgs) => {
                this.loading = false;
                console.log(mgs);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            window.open(`${process.env.VUE_APP_PAY}login/:SUP/`, '_self');
          }
        }
      });
    },
    // 空间使用
    getPrepress() {
      API.prepressProject().then(res =>{
        this.prepress = res.message.data
      })
    },
    // 消息中心
    hamdleInfo() {
      this.dialog.status = 'info';
      this.dialog.info = true;
    },
    handleSpace() {
      this.dialog.space =true
    },
    // 退出登录
    exit() {
      this.$confirm('确定要退出此用户?', '提示', {
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          store.dispatch('logout').then(() => {
            cookies.remove('token');
            window.open(`${this.VUE_APP_HOME}`, '_self');
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          });
        });
    },
    // 获取个人信息
    getUserInfo() {
      store.dispatch('getInfo').then((res) => {
        if (res.message.success) {
          this.userInfo = res.message.data;
          this.userInfo.roleIds.split(',').forEach(item => {
            if (item === 'afaaeaa3-5e93-44ad-b204-2121f400d436') {
              this.prepressShow = true
            }
          })
          // console.log(this.userInfo)
          this.defaultUrl = res.message.data.url;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$dark_gray: #889aa4;
header {
  .el-dialog__header {
    background-color: #fff;
    font-size: 16px;
  }
  ::v-deep .el-dialog__title {
    font-size: 16px;
  }
  ::v-deep .el-dialog__body {
    padding: 0px 20px;
  }
  //.el-dialog__body{
  //  border-bottom: 1px solid;
  //}
  .info-box {
    //margin: 20px 0;
    //padding-bottom: 25px;
    //border-top: 1px solid;
    // 内容
    .content {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      /*line-height: 64px;*/
      white-space: nowrap;
      padding: 15px 5px;
      background: #fff;
      list-style: none;
      cursor: s-resize;
      border-bottom: 1px solid rgb(192, 190, 191);
      &:before {
        font-size: 4px;
        padding-right: 10px;
      }
      &:nth-child(even) {
        background: #fff;
      }
      &:hover {
        color: rgb(37, 23, 23);
      }
      &.active {
        color: rgb(38, 27, 31);
        //border-bottom: 1px solid rgb(192, 190, 191);
      }
      // 文本
      .text {
        flex: 1 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #231815;
      }
      // 日期
      .date {
        flex: 0 0 200px;
        text-align: right;
      }
    }
  }
  .buttonCancel{
    border: 1px solid #FFDD8B;
    background-color:#FFFAED;
    color: rgb(235, 155, 2);
    margin: 0px 5px 20px;
    padding: 8px 34px;
    float: right;
  }
  .button {
    //width: 100%;
    background-color: rgb(235, 155, 2);
    color: #fff;
    margin: 0px 5px 20px;
    padding: 8px 34px;
    float: right;
  }
  .padding {
    padding: 9px 0;
    color: #231815;
    span {
      padding-left: 19px;
      font-size: 14px;
    }
  }
  background: #ffffff;
  font-size: 14px;
  .header-box {
    margin: auto;
    height: 62px;
    background: #3e2b25;
    box-shadow: 0px 2px 6px 3px #bcbcbc;
    .right-box {
      float: right;
      margin-right: 40px;
      height: 100%;
      display: flex;
      align-items: center;
    }
    #logo {
      height: 62px;
      width: 202px;
      text-align: center;
      .img {
        margin-top: 14px;
        height: 41px;
        width: 106px;
      }
    }
    .head li {
      width: 80px;
      height: 62px;
      line-height: 62px;
      float: left;
      // margin-right: 80px;
      cursor: pointer;
      .exit {
        width: 120px;
        height: 57px;
        line-height: 57px;
      }
    }
    .exit:hover {
      color: #f3a000;
    }
    .head > li a:hover {
      color: #f3a000;
    }
    .UserName {
      width: 200px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      float: right;
      cursor: pointer;
      color: #231815;
      position: relative;
    }
    .UserName:hover .menu {
      display: block;
      transition: all ease 0.3s;
    }
    .information {
      width: 120px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      float: right;
      cursor: pointer;
      color: #231815;
    }
    .menu {
      display: none;
      position: absolute;
      top: 60px;
      right: 0px;
      border: 1px solid #9999;
      background: #ffffff;
      z-index: 9;
      .pullDown {
        width: 62px;
        height: 60px;
        line-height: 60px;
        text-decoration: none;
        display: block;
      }
      li {
        width: 62px;
        height: 60px;
        color: #231815;
      }
    }
    .pullDown:hover {
      color: #f3a000;
      transition: all ease 0.3s;
    }
    .menu > li:hover a {
      color: #f3a000;
      transition: all ease 0.3s;
    }
  }
}
.pullDown {
  color: #231815;
}
.pullDown {
  width: 120px;
  height: 60px;
  line-height: 60px;
  text-decoration: none;
  display: block;
}
.Name {
  color: #f3a000;
  font-size: 18px;
  margin: 0px 10px;
}
.nav{
  margin-right: 20px;
  margin-left: 40px;
}
.FristPage {
  width: 80px;
  height: 62px;
  line-height: 62px;
  display: block;
  color: #231815;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  text-decoration: none;

}
.FristPageFactory{
  width: 90px;
}
.head {
  .active {
    color: #f3a000;
    text-decoration: none;
    transition: all ease 0.3s;
  }
}
.active {
  color: #f3a000;
  /*border-bottom: 10px solid #F3A000;*/
  text-decoration: none;
  transition: all ease 0.3s;
  &:after {
    display: block;
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
}
.active1 {
  color: #f3a000;
  text-decoration: none;
  transition: all ease 0.3s;
}
.right {
  display: flex;
  margin-right: 20px;
  .IMG {
    width: 36px;
    height: 36px;
  }
}
.name {
  color: #f3a000;
  height: 62px;
  line-height: 62px;
  text-align: center;
  font-weight: 700;
  margin-right: 15px;
}
.block {
  //height: 40px;
  //line-height: 40px;
  //margin-top: 10px;
}
// 获取验证码按钮
.captcha-btn {
  position: absolute;
  top: 0;
  right: 10px;
}
// 上传头像
.headImg {
  display: flex;
  align-items: center;

  // 文本
  .avatar-text {
    width: 80px;
  }
  // 上传按钮
  .upload-demo {
    height: 70px;
    width: 70px;
    margin-left: -70px;
    border: 0;
    color: #fff;
    &:hover {
      border-radius: 50%;
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  .buttonUp {
    opacity: 0;
    padding: 29px 10px;
    border: 0;
    &:hover {
      color: #fff;
      border-radius: 50%;
      opacity: 1;
    }
  }
}
.form1 {
  padding: 10px 5px 0px;
}
.li-text {
  text-align: center;
  //padding: 20px 0px;
  color: #231815;
  cursor: pointer;
  font-size: 14px;
}
.li-text div {
  cursor: pointer;
}
.li {
  font-size: 14px;
}
.li-font {
  padding: 20px 0px;
  cursor: pointer;
  color: #000;
}
.li1:hover {
  background-color: rgb(254, 246, 230);
}
.show-pwd {
  position: absolute;
  right: 10px;
  top: 7px;
  font-size: 16px;
  color: $dark_gray;
  cursor: pointer;
  user-select: none;
}

@media screen and (max-width:800px) {
  .header-box {
    display: none;
  }
}
</style>
