// import request from "../../../../adminplat/src/utils/request";

export default ({ request }) => ({
  getDeviceList(data) {
    return request({
      url: '/dhk/list',
      method: 'post',
      data,
    });
  },
  deleteDevice(data) {
    return request({
      url: '/dhk/delete',
      method: 'post',
      data,
    });
  },
  getDevicePrintTypeList() {
    return request({
      url: '/dhk/config/type/print/list',
      method: 'post',
    });
  },
  importDevice(data) {
    return request({
      url: '/dhk/device/import',
      method: 'post',
      data,
    });
  },
  // 创建设备
  createDevice(data) {
    return request({
      url: '/dhk/create',
      method: 'post',
      data,
    });
  },
  // 获取设备类型
  getDeviceConfigList() {
    return request({
      url: '/dhk/config/list',
      method: 'post',
    });
  },
  // 获取设备类型不包含品牌类型
  getDeviceBrandList(data) {
    return request({
      url: '/dhk/config/brandList',
      method: 'post',
      data,
    });
  },
  createDeviceMaintain(data) {
    return request({
      url: '/dkh/maintain/create',
      method: 'post',
      data,
    });
  },
  // 获取设备配置
  getDeviceConfig(data) {
    return request({
      url: '/psl/keyvalue/config/device',
      method: 'post',
      data,
    });
  },

  // 获取策略
  getTemplateListByType(data) {
    return request({
      url: '/dhk/template/list/maintainbytype',
      method: 'post',
      data,
    });
  },

  // 查询团队用户下拉
  getTeamUserXiaLa(data) {
    return request({
      url: '/psl/team/user/get/drop',
      method: 'post',
      data,
    });
  },
  // 设备明细
  getDeviceInfo(data) {
    return request({
      url: '/dhk/info',
      method: 'post',
      data,
    });
  },
  /**
   * 查询设备明细
   * @param { Object }data
   */
  deviceInfo(data) {
    return request({
      url: '/web/device/info',
      method: 'post',
      data,
    });
  },
  /**
   * 查询产品列表
   * @param { null }
   */
  // 获取产品列表
  prodectList() {
    return request({
      url: '/web/product/list',
      method: 'post',
    });
  },
  // 创建设备
  // createDevice(data) {
  //   return request({
  //     url: '/dhk/create',
  //     method: 'post',
  //     data: data
  //   })
  // },
  // 查询设备序略号列表
  // getDeviceOrderList(data) {
  //   return request({
  //     url: '/dhk/order/list',
  //     method: 'post',
  //     data: data
  //   })
  // },
  /** *
   * 获取模板列表
   * @{Obeject} data
   */
  getDeviceTemplateList(data) {
    return request({
      url: '/dhk/template/list',
      method: 'post',
      data,
    });
  },
  /**
   * 模板列表 -- 删除
   * @param {Object} data
   */
  deleteTemplate(data) {
    return request({
      url: '/dhk/template/delete',
      method: 'post',
      data,
    });
  },
  // 复制模版
  copyTemplate(data) {
    return request({
      url: '/dhk/template/copy',
      method: 'post',
      data,
    });
  },
  getDeviceTemplateDetail(data) {
    return request({
      url: '/dhk/template/detail',
      method: 'post',
      data,
    });
  },
  // getDeviceConfigList() {
  //   return request({
  //     url: '/dhk/config/list',
  //     method: 'post'
  //   })
  // },
  updateTemplate(data) {
    return request({
      url: '/dhk/template/modify',
      method: 'post',
      data,
    });
  },
  createTemplate(data) {
    return request({
      url: '/dhk/template/create',
      method: 'post',
      data,
    });
  },
  // 导入模板
  importTemplate(data) {
    return request({
      url: '/dhk/template/import',
      method: 'post',
      data,
    });
  },
  // 导出模板
  exportTemplate(id) {
    return request({
      url: `/web/template/export/${id}`,
      method: 'get',
    });
  },
  createDevice1(data) {
    return request({
      url: '/dhk/create',
      method: 'post',
      data,
    });
  },
  // 查询团队用户下拉
  // getTeamUserXiaLa(data) {
  //   return request({
  //     url: '/psl/team/user/get/drop',
  //     method: 'post',
  //     data: data
  //   })
  // }
  // 异常提报和定期更换列表
  abnormalDataList(params) {
    return request({
      url: '/newApp/abnormalData/list',
      method: 'get',
      params,
    });
  },
  // 异常提报和定期更换原因添加
  abnormalDataAdd(data) {
    return request({
      url: 'newApp/abnormalData/add',
      method: 'post',
      data,
    });
  },
  //异常提报和定期更换原因状态变更
  abnormalDataState(data) {
    return request({
      url: '/newApp/abnormalData/state',
      method: 'put',
      data,
    });
  },
  // 异常提报和定期更换原因删除
  abnormalDataDelete(id) {
    return request({
      url: `/newApp/abnormalData/${id}`,
      method: 'delete',
    });
  }
});
