export default ({ request }) => ({
  getClassDate(params) {
    return request({
      url: '/teamGroup/list',
      method: 'get',
      params,
    });
  },
  // 印量分析获取设备
  getClassDateList(params) {
    return request({
      url: 'teamGroup/listByDeviceId',
      method: 'get',
      params,
    });
  },
  addClassTeam(data) {
    return request({
      url: '/teamGroup/add',
      method: 'post',
      data,
    });
  },
  editClassTeam(data, id) {
    return request({
      url: `/teamGroup/update/${id}`,
      method: 'put',
      data,
    });
  },
  deleteClassTeam(id) {
    return request({
      url: `/teamGroup/update/${id}`,
      method: 'delete',
    });
  },
  // getClass(data) {
  //     return request({
  //         url: '/order/invoice/detail',
  //         method: 'get',
  //         params: data,
  //     });
  // },
});
