export default ({ request }) => ({
  getSelfMenu() {
    return request({
      url: '/psl/role/permission/menus',
      method: 'post',
    });
  },
  // 创建团队
  createTeam(data) {
    return request({
      url: '/psl/team/create',
      method: 'post',
      data,
    });
  },

  // 查询团队
  search(data) {
    return request({
      url: '/psl/team/search',
      method: 'post',
      data,
    });
  },

  // 修改团队信息
  modify(data) {
    return request({
      url: '/psl/team/modify',
      method: 'post',
      data,
    });
  },

  // 删除团队成员
  deleteUser(data) {
    return request({
      url: '/psl/team/user/delete',
      method: 'post',
      data,
    });
  },

  // 查询是否有团队
  checkTeamUser() {
    return request({
      url: '/psl/team/user/check',
      method: 'post',
    });
  },

  // 查询团队用户
  //   getTeamUser(data) {
  //   return request({
  //     url: '/psl/team/user/get',
  //     method: 'post',
  //     data: data
  //   })
  // }

  // 查询团队用户下拉
  getTeamUserXiaLa(data) {
    return request({
      url: '/psl/team/user/get/drop',
      method: 'post',
      data,
    });
  },

  // 团队用户保存角色
  saveRole(data) {
    return request({
      url: '/psl/team/user/role/save',
      method: 'post',
      data,
    });
  },
  // 角色
  CompanyRole() {
    return request({
      url: '/psl/role/team/roles',
      method: 'post',
    });
  },
  // 申请加入团队
  apply(data) {
    return request({
      url: '/teamApply/apply',
      method: 'post',
      data,
    });
  },

  // 拒绝加入团队
  refuse(data) {
    return request({
      url: '/teamApply/refuse',
      method: 'post',
      data,
    });
  },

  // 同意加入团队
  agree(data) {
    return request({
      url: '/teamApply/agree',
      method: 'post',
      data,
    });
  },

  // 查询申请列表
  searchApplyUser() {
    return request({
      url: '/teamApply/searchApplyUser',
      method: 'post',
    });
  },

  // 查询申请列表
  getInfo() {
    return request({
      url: '/psl/team/get',
      method: 'post',
    });
  },

  // 查询申请列表
  invite(data) {
    return request({
      url: '/psl/team/apply/invite',
      method: 'post',
      data,
    });
  },

  // 查询申请列表
  getInitCities() {
    return request({
      url: '/psl/team/cities',
      method: 'post',
    });
  },

  // 查询申请列表
  getInitAreas() {
    return request({
      url: '/psl/team/areas',
      method: 'post',
    });
  },

  // 获取团队列表
  getTeamList() {
    return request({
      url: '/psl/team/list',
      method: 'post',
    });
  },

  // 查询申请列表
  uploadLogo(data) {
    return request({
      url: '/psl/team/logo/upload',
      method: 'post',
      data,
    });
  },
});
