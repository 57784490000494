import { router } from '@/router';
import cookies from '@/utils/cookies.js';
import { store } from '@/store';
import {getToken} from "../../adminplat/src/utils/auth";
const whiteList = ['/waterTankMonitoring','/equipmentBig']; // no redirect whitelist

router.beforeEach((to, from, next) => {
  console.log(getToken())
  // if (getToken()) {
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
      return;
    }
    if (to.matched.length === 0) {
      next('/404');
      return;
    }
    //   console.log('from=====>' + from.path)
    //   console.log('to=====>' + to.path)
    const userInfo = cookies.get('userInfo');
    if (to.path === '/PrintSteward') {
      if (userInfo === '0') {
        next('/PrintSteward/NoOpen');
        return;
      }
      store.dispatch('reSetMenu', '/PrintSteward/PrintHome');
      store.dispatch('reSetMenuArr', ['/PrintSteward/PrintHome']);
      // store.dispatch('reSetMenuArr', ['/PrintSteward/PrintHome'])
      next('/PrintSteward/PrintHome');
      return;
    }
    if (to.path === '/Course/Selection') {
      store.dispatch('reSetCourseMenu', '/Course/Selection');
    }
    next();
  // } else {
  //   if (whiteList.indexOf(to.path) !== -1) {
  //     // in the free login whitelist, go directly
  //     next();
  //   } else {
  //     // other pages that do not have permission to access are redirected to the login page.
  //     next(`/login?redirect=${to.path}`);
  //     NProgress.done();
  //   }
  // }

});
