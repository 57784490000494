export default ({ request }) => ({
  // 获取省
  getProvince() {
    return request({
      url: '/psl/provinces/get',
      method: 'post',
    });
  },

  // 获取市
  getCity(data) {
    return request({
      url: '/psl/cities/get',
      method: 'post',
      data,
    });
  },

  // 获取区
  getArea(data) {
    return request({
      url: '/psl/areas/get',
      method: 'post',
      data,
    });
  },
});
