import Vue from 'vue';
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import * as echarts from 'echarts';
import 'echarts-liquidfill'
import App from './App.vue';
import { router } from '@/router';
import { store } from '@/store';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/style.scss'; // 自定义样式
import '@/components/sidebarStyle.scss';
import '@/permission';
import '@/icons';
import screenfull from "screenfull";
import "amfe-flexible"
// 图表
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueRouter);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
