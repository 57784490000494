export default ({ request }) => ({
  getMaintenanceDate(params) {
    return request({
      url: '/outRepainting/list',
      method: 'get',
      params,
    });
  },
  addMaintenanceDate(data) {
    return request({
      url: '/outRepainting/add',
      method: 'post',
      data,
    });
  },
  editMaintenanceDate(data, id) {
    return request({
      url: `/outRepainting/update/${id}`,
      method: 'put',
      data,
    });
  },
  editMaintenancePassword(data, id) {
    return request({
      url: `/outRepainting/updateOutRepainting/${id}`,
      method: 'put',
      data,
    });
  },
  editMaintenanceState(data, id) {
    return request({
      url: `/outRepainting/update/state/${id}`,
      method: 'put',
      data,
    });
  },
});
