export default ({request}) => ({
  /**
   * 技术支持 -- 列表
   * @param { Object } data
   */
  technicalSpportList(data) {
    return request({
      url: '/document/technicalList',
      method: 'get',
      params: data,
    });
  },
  /**
   * 技术支持 -- 上架下架
   * @param { id:value } id
   * @param { object } data
   */
  update(data, id) {
    return request({
      url: `/document/${id}/delete`,
      method: 'put',
      data,
    });
  },
  /**
   * 技术支持 -- 添加技术支持
   * @param { object } data
   */
  addSupport(data) {
    return request({
      url: '/document/add',
      method: 'post',
      data,
    });
  },
  /**
   * 技术支持 -- 详情
   * @param { object } data
   */
  SupportInfo(data) {
    return request({
      url: '/document/tecById',
      method: 'get',
      params: data,
    });
  },
  /**
   * 技术支持 -- 编辑技术支持
   * @param { object } data
   */
  EditSupport(data, id) {
    return request({
      url: `/document/${id}/update`,
      method: 'put',
      data,
    });
  },
// 添加分类
  addSupportClass(data) {
    return request({
      url: `/document/addclass`,
      method: 'post',
      data,
    });
  },
  getDocumentList(data) {
    return request({
      url: "/document/listbyname",
      method: "post",
      data,
    });
  },
  getclasslist(data) {
    return request({
      url: '/document/getclasslist',
      method: 'get',
      params: data,
    });
  },
// 获取视频
  getVodPlayInfo(data) {
    return request({
      url: "/document/getVodPlayInfo",
      method: "get",
      params: data,
    });
  }
});
