<template>
  <!--控制台-->
  <div class="Home">
    <!--顶部导航栏-->
    <on-header />
    <!--内容-->
    <router-view />
    <!--右侧：悬浮-->
    <sidebarRight />
  </div>
</template>

<script>
import OnHeader from '../components/Header';
import sidebarRight from '@/components/sidebar-right'; // 右侧悬浮

export default {
  name: 'HomeName',
  components: {
    OnHeader,
    sidebarRight,
  },
};
</script>

<style lang="scss" scoped>
.Home {
  width: 100%;
  min-width: 1280px;
}
</style>
