<template>
  <div class="PlayerName">
    <on-header></on-header>
    <main>
      <div class="box">
        <div class="tittle">
          <div style="margin-left:50px;">{{ courseUser.name }}</div>
        </div>
        <div class="play-box">
          <div id="playerCon"></div>
        </div>
      </div>
      <div class="sidebar">
        <div class="side-box" style="font-size: 20px;">
          <div @click="tab = 1" :class="tab == 1 ? 'active' : ''">章节</div>
          <div @click="testButton" :class="tab == 2 ? 'active' : ''">考试</div>
        </div>
        <div class="content" v-show="tab == 1">
          <div v-for="item in courseInfo" :key="item.id" class="bar">
            <p style="color: #ffffff;">{{ item.name }}</p>
            <hr />
            <div v-for="(items, index) in item.list" :key="items.id">
              <li
                class="course"
                @click="getPlay(items, index, item.id)"
                :class="color == items.id ? 'active' : ''"
              >
                {{ items.name }}
              </li>
            </div>
          </div>
        </div>
        <div class="content" v-show="tab == 2">
          <div class="tesk-title">考试说明</div>
          <ul class="teskContent">
            <li>1.采取随机出题的形式进行。</li>
            <li>2.必须在规定的时间范围内完成。</li>
            <li>3.只有所有章节中的所有课程都学完后才可以进行考试。</li>
            <li>4.每题1分，提交考试答案后系统会自动计算考试结果。</li>
            <li>5.若考试结果为未通过，您需要重新学习课程并重新考试。</li>
            <li>6.考试不限次数，但每次考试题目可能会不一样。</li>
          </ul>
          <div class="butn">
            <button v-if="immediatelyTest === 1" class="btn" @click="ToTest">立即考试</button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script
  charset="utf-8"
  type="text/javascript"
  src="https://g.alicdn.com/de/prismplayer/2.9.3/aliplayer-min.js"
></script>
<script>
import OnHeader from '../components/PlayerHeader'
import API from '@/api'
import cookies from '@/utils/cookies'
export default {
  name: 'playerName',
  components: {
    OnHeader
  },
  data() {
    return {
      immediatelyTest: Number,
      tab: 1,
      active2: false,
      param: {
        id: ''
      },
      courseInfo: [], //课程详情
      courseUser: '',
      userName: '',
      // userInfo: '',
      PlayInfo: '',
      player: '',
      playerParams: {
        video: undefined,
        playauth: undefined,
        videoId: undefined
      },
      color: '',
      chapterId: '',
      chapterIndex: ''
    }
  },
  created() {
    this.getCourseUserInfo()
    // this.getUserInfo()
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    testButton() {
      this.tab = 2
      API.getTest({ id: this.$route.query.courseId }).then(res => {
        if (res.message.message) {
          this.immediatelyTest = res.message.data.test
        }
      })
    },
    Goback() {
      this.$router.go(-1)
    },
    // 点击返回
    back() {
      this.$router.push('/HomePage')
    },
    // 退出登录
    exit() {
      cookies.remove('token')
      this.getUserInfo()
    },
    // 立即考试
    ToTest() {
      let courseId = this.$route.query.courseId
      let courseUserId = this.$route.query.courseUserId
      this.$router.push({
        path: '/Course/MyCourse/Test',
        query: {
          courseId: courseId,
          courseUserId: courseUserId
        }
      })
    },
    //查询课程详情
    getCourseUserInfo() {
      this.param.id = this.$route.query.courseUserId
      API.CourseUserInfo(this.param)
        .then(res => {
          if (res.message.success == true) {
            this.courseInfo = res.message.data.list
            this.courseUser = res.message.data
            if (this.courseUser.sectionId && this.courseUser.chapterId) {
              const sectionId = {
                id: this.courseUser.sectionId
              }
              this.getPlay(sectionId, 0, this.courseUser.chapterId)
            } else {
              const DefaultSectionId = this.courseUser.list[0].id
              const DefaultCourseId = { id: this.courseUser.list[0].list[0].id }
              this.getPlay(DefaultCourseId, 0, DefaultSectionId)
            }
          }
        })
        .catch(mgs => {
          console.log(mgs)
        })
    },
    // 结束后下一个
    endedHandle() {
      API.EndPlay({ sectionUserId: this.color }).then(res => {
        if (!res.message.success) {
          this.$message({
            message: '请重新学习此课程！',
            type: 'error',
            duration: 3000
          })
          return
        }
      })
      let currentChapter = this.courseInfo.find((item, chaptIndex) => {
        return item.id == this.chapterId
      })
      for (let index = 0; index < currentChapter.list.length; index++) {
        const element = currentChapter.list[index]
        if (element.id != this.color) {
          continue
        } else {
          if (index == currentChapter.list.length - 1) {
            var nextchaptIndex
            this.courseInfo.find((item, chaptIndex) => {
              if (item.id == this.chapterId) {
                nextchaptIndex = chaptIndex + 1
              }
            })
            currentChapter = this.courseInfo[nextchaptIndex]
            let tempId = currentChapter.list[0]
            this.getPlay(tempId, 0, currentChapter.id)
          } else {
            let tempId = currentChapter.list[index + 1]
            this.getPlay(tempId, this.chapterIndex, this.chapterId)
          }
          break
        }
      }
    },
    getPlay(items, index, itemId) {
      this.chapterIndex = index
      this.chapterId = itemId
      API.getPlay({ id: items.id }).then(res => {
        if (this.player) {
          this.player.dispose()
        }
        this.getInfo(res.message.data, items.id)
      })
    },
    //点击播放课程
    getInfo(items, id) {
      let video = items.url
      let playauth = items.pinzhen
      let videoId = items.videoId
      this.color = id
      this.player = new Aliplayer({
        id: 'playerCon',
        source: video,
        width: '100%',
        height: '82vh',
        format: 'mp4',
        qualitySort: 'asc',
        preload: true, //播放器自动加载，目前仅H5可用。
        autoplay: true, //自动播放
        playsinline: true,
        isLive: false,
        rePlay: false,
        controlBarVisibility: 'hover',
        useH5Prism: true,
        vid: videoId, //播放的VideoId
        playauth: playauth, //获取凭证
        encryptType: 1 //当播放私有加密流时需要设置。
      })
      this.player.on('ended', this.endedHandle)
    }
  }
}
</script>

<style lang="scss" scoped>
.PlayerName {
  background: #131313;
  height: 100vh;
  min-width: 1280px;
}
main {
  background: #131313;
  display: flex;
  justify-content: space-between;
  .box {
    width: 80%;
    height: 86vh;
    background: #000000;
    .tittle {
      color: #f7b515;
      line-height: 40px;
      background: #131313;
      font-size: 20px;
    }
    .play-box {
      max-width: 100%;
    }
  }

  .sidebar {
    height: calc(94vh - 70px);
    width: 20%;
    padding: 0 20px;
    background: #131313;
    overflow: hidden;
    overflow-y: auto;
    .side-box {
      display: flex;
      color: #aaaaaa;
      div {
        margin: 20px;
        cursor: pointer;
      }
      div:hover {
        color: #ffffff;
      }
    }
    .course {
      width: 300px;
      margin: 10px 0;
      cursor: pointer;
      color: rgb(153, 153, 153);
    }
    .active {
      color: #f7b515;
    }
    .course:hover {
      color: #f7b515;
      cursor: pointer;
    }
  }
}
.active {
  color: #ffffff;
}
.tesk-title {
  color: #ffffff;
}
.teskContent {
  color: #ffffff;
  font-size: 12px;
  li {
    margin: 20px 0;
  }
}
.butn {
  text-align: center;
}
.btn {
  width: 200px;
  height: 40px;
  line-height: 40px;
  margin: 20px;
  border: 1px solid #f7b515;
  background: #f7b515;
  color: #ffffff;
  cursor: pointer;
}
</style>
