<template>
  <!--仪表盘-->
  <div class="chart2">
    <!--图表-->
    <div :id="id"  class="chart"/>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: [String, Number],
      default: 'chart'
    },
    // 标题
    title: {
      type: String,
      default: ''
    },
    // 标题字体大小
    titleFontSize: {
      type: Number,
      default: 14
    },
    // 标题对齐方式
    titleTextAlign: {
      type: String,
      default: 'auto'
    },
    // 标题离容器上方的距离
    titleTop: {
      type: String,
      default: 'middle'
    },
    // 标题离容器右侧的距离
    titleRight: {
      type: String,
      default: 'center'
    },
    // 名称
    name: {
      type: String,
      default: ''
    },
    // 总数
    total: {
      type: Number,
      default: 0
    },
    value: {
      type: Number,
      default: 0
    },
    allSpace: {
      type: Number,
      default: 0
    },
    usedSpace: {
      type: Number,
      default: 0
    },
    // 总数上方距离
    totalTop: {
      type: [String, Number],
      default: '50%'
    },
    // 总数左侧距离
    totalLeft: {
      type: [String, Number],
      default: '0%'
    },
    // 图表数据
    data: {
      type: Array,
      default: () => []
    },
    // 图表宽度
    width: {
      type: String,
      default: '100%'
    },
    // 图表高度
    height: {
      type: String,
      default: '250px'
    },
    // 饼图的中心（圆心）坐标
    center: {
      type: Array,
      default: () => ['50%', '50%']
    },
    // 饼图半径
    radius: {
      type: Array,
      default: () => ['40%', '80%']
    },
    // 是否显示图例
    legendShow: {
      type: Boolean,
      default: false
    },
    // 图例排列方向
    legendOrient: {
      type: String,
      default: ''
    },
    // 图例离容器上侧的距离
    legendTop: {
      type: String,
      default: 'middle'
    },
    // 图例离容器右侧的距离
    legendRight: {
      type: String,
      default: '0%'
    },
    // 图例字号
    legendFontSize: {
      type: Number,
      default: 12
    },
    // 图例宽度
    legendWidth: {
      type: [Number,String],
      default: 'auto'
    },
    // 饼图颜色
    color: {
      type: Array,
      default: () => ['#FFCC18','#FB5052','#B076FF','#4CBBF9','#006df0', '#0acf97', '#fc7f03', '#d149fe', '#fe6d65', '#fcd02d', '#9ad864']
    }
  },
  data() {
    return {
      // 图表配置
      optionEcharts: {
        backgroundColor: '#fff',
        title: [
          {
            text: '云盘空间已使用' + this.usedSpace + 'MB' + '\n' + '{b|共'+ this.allSpace +'GB}',
            y: '65%',
            x: 'center',
            textStyle: {
              lineHeight:35,
              // fontWeight: 'bold',
              fontSize: 16,
              color: '#3D3D3D',
              rich: {
                b: {
                  color:'#AFAFAF',
                  fontSize:16,
                  lineHeight:25
                }
              }
            },
          },
        ],
        angleAxis: {
          show: false,
          max: (100 * 360) / 180, //-45度到225度，二者偏移值是270度除360度
          type: 'value',
          startAngle: 180, //极坐标初始角度
          splitLine: {
            show: false,
          },
        },
        barMaxWidth: 30, //圆环宽度
        radiusAxis: {
          show: false,
          type: 'category',
        },
        //圆环位置和大小
        polar: {
          center: ['50%', '55%'],
          radius: '150%',
        },
        series: [],
      }
    }
  },
  watch: {
    // 监听数据集
    data: {
      deep: true,
      handler(val) {
        console.log(val)
        // this.optionEcharts.dataset.source = val.source;
        this.initChart();
      },
    },
  },
  mounted() {
    this.charData(); // 处理数据
    this.initChart(); // 初始化echarts实例
  },
  methods: {
    charData() {
      this.optionEcharts.series = [
        {
          type: 'bar',
          data: [
            {
              //上层圆环，显示数据
              value: this.value,
              itemStyle: {
                color: '#FF9900',
              },
            },
          ],
          barGap: '-100%', //柱间距离,上下两层圆环重合
          coordinateSystem: 'polar',
          roundCap: true, //顶端圆角
          z: 9999, //圆环层级，同zindex
        },
        {
          //下层圆环，显示最大值
          type: 'bar',
          data: [
            {
              value: 100,
              itemStyle: {
                color: '#EFEFEF',
                borderWidth: 0,
              },
            },
          ],
          barGap: '-100%',
          coordinateSystem: 'polar',
          roundCap: true,
          z: -1,
        },
        //仪表盘
        {
          type: 'gauge',
          startAngle: 225, //起始角度，同极坐标
          endAngle: -45, //终止角度，同极坐标
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLabel: {
            show: false,
          },
          pointer: {
            // 分隔线
            show:false,//是否显示指针
            shadowColor: 'auto', //默认透明
            shadowBlur: 5,
            length: '50%',
            width: '2',
          },

          itemStyle: {
            color: '#1598FF',
            borderColor: '#1598FF',
            borderWidth: 3,
          },
          detail: {
            formatter: function (params) {
              return '{a|已使用} '+'\n' + params + '{b|%}';
            },
            rich: {
              a: {
                color:'#3D3D3D',
                fontSize:20,
                lineHeight:40
              },
              b: {
                color:'#BDBDBD',
                fontSize:20,
                lineHeight:90
              }
            },
            color: '#000000',
            fontSize: 60,
            offsetCenter: [5, -5],
          },
          title: {
            show: false,
          },
          data: [
            {
              value: this.value,
            },
          ],
        }
      ]
    },
    initChart() {
      // const char = this.$echarts.init(document.getElementById(this.id))
      const echarts = require('echarts');
      const chartList = document.getElementById(this.id);
      // 初始化echarts实例
      const chart = echarts.init(chartList);
      this.$nextTick(() => {
        // 图表配置
        chart.setOption(this.optionEcharts);
        // 图表的自适应
        window.addEventListener('resize', () => {
          chart.resize();
        });
      });
    },
  },
}
</script>

<style lang="scss" scoped>
// 图表
.chart2 {
  //min-width: 250px;
  width: 100%;
}
.chart {
  height: 350px;
}
// 总数
.total {
  position: absolute;
  //top: 45%;
  //font-size: 10px;
  width: 100%;
  text-align: center;
  // 数量
  .num {
    //font-size: 14px;
    font-weight: bold;
  }
}
</style>
