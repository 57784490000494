export default ({request}) => ({
    QualityReportList(params) {
        return request({
            url: '/qualityReport/list',
            method: 'get',
            params,
        });
    },
    QualityReportAdd(data) {
        return request({
            url: '/qualityReport/add',
            method: 'post',
            data,
        });
    },
    QualityReportDeviceList(params) {
        return request({
            url: '/qualityReport/deviceList',
            method: 'get',
            params,
        });
    },
    PanelList(params) {
        return request({
            url: '/panel/list',
            method: 'get',
            params,
        });
    },
    PanelAdd(data) {
        return request({
            url: '/panel/add',
            method: 'post',
            data,
        });
    },
    PanelUpdate(data,id) {
        return request({
            url: `/panel/update/${id}`,
            method: 'put',
            data
        });
    },
    PanelDelete(id) {
        return request({
            url: `/panel/delete/${id}`,
            method: 'delete',
        });
    },
    DaPingJiTai() {
        return request({
            url: '/newApp/getDaPingJiTai',
            method: 'get',
            timeout: 15 * 1000,
        });
    },
    DaPingJiTaiBig(params) {
        return request({
            url: '/psl/user/getDaPingJiTai',
            method: 'get',
            params,
            timeout: 15 * 1000,
        });
    },
    // 设备列表
    DeviceListBig(params) {
        return request({
            url: '/psl/user/deviceListByTeamId',
            method: 'get',
            params
        });
    },
    DaPingLaoBan() {
        return request({
            url: '/newApp/getDaPingLaoBan',
            method: 'get',
        });
    },
    DaPingCheJian(params) {
        return request({
            url: '/newApp/getDaPingCheJian',
            method: 'get',
            params
        });
    },
    waterDeviceInfo(params) {
        return request({
            url: 'psl/user/getDeviceInfo',
            method: 'get',
            params
        });
    },
    // app日报表数据
     teamDayInfoReport(data) {
        return request({
            url: "/newApp/teamDayInfoReport",
            method: "get",
            params: data,
        });
    },
// app周报表数据
     teamDayWeekReport(data) {
        return request({
            url: "/newApp/teamDayWeekReport",
            method: "get",
            params: data,
        });
    },
// app月报表数据
     teamDayMonthReport(data) {
        return request({
            url: "/newApp/teamDayMonthReport",
            method: "get",
            params: data,
        });
    },
});
