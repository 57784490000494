import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers.token = getToken();
      config.headers['Content-Type'] = 'application/json';
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  },
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== '200' || !res.message.success) {
      Message({
        message: res.message.message || 'Error',
        type: 'error',
        duration: 2000,
        // showClose: true
      });

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === '510') {
        // to re-login
        MessageBox.confirm('您已经退出登录，您可以取消弹窗继续留在本页或者重新登录', '登出确认', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            location.reload();
          });
        });
      }
      //
      return Promise.reject(new Error(res.message.message || 'Error'));
    } else {
      return res.message.data;
    }
  },
  (error) => {
    error.message = '系统错误,请稍后再试。'; // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 2000,
    });
    return Promise.reject(error);
  },
);

export default service;
